export const packagecolumns = [
  {
    title: "Title",
    styles: "basis-[30%]",
    sorting: true,
    key: "title",
  },
  {
    title: "Type",
    styles: "basis-[7%]",
    sorting: false,
    key: "type",
  },
  // {
  //   title: "Length",
  //   styles: "basis-[6%]",
  //   sorting: false,
  //   key: "length",
  // },
  // {
  //   title: "Width",
  //   styles: "basis-[6%]",
  //   sorting: false,
  //   key: "width",
  // },
  // {
  //   title: "Height",
  //   styles: "basis-[6%]",
  //   sorting: false,
  //   key: "height",
  // },
  {
    title: "Weight (lbs)",
    styles: "basis-[15%]",
    sorting: false,
    key: "weight",
  },
  {
    title: "Cost",
    styles: "basis-[15%]",
    sorting: false,
    key: "cost",
    usd: true,
  },
  // {
  //   title: "Cost Calculator",
  //   styles: "basis-[15%]",
  //   sorting: false,
  //   key: "want_to_use_calculator",
  //   bool_value: true,
  // },
  {
    title: "Package Check Off",
    styles: "basis-[19%]",
    sorting: false,
    key: "package_check_off",
    package_check_off: true,
  },
  // // custom_cost_per_pound
  {
    title: "Location",
    styles: "basis-[15%]",
    sorting: false,
    key: "location",
  },
  {
    title: "Cost Per Pound",
    styles: "basis-[15%]",
    sorting: false,
    key: "custom_cost_per_pound",
    usd: true,
  },
  {
    title: "Action(s)",
    styles: "basis-[8%]",
    sorting: false,
    key: null,
    name: "action",
  },
];
