import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import {
  Model,
  initialValues,
  validationSchema
} from "../../../../../formModel/fee"
import { BackDropLoader } from "../../../../common/loader"
import FeeForm from "./FeeForm"
import { calculateTotalWeight } from "../../../../../utils/format"

const FeeDialog = ({
  data,
  workorder,
  type: PackageType,
  isLoading,
  onFormSubmit,
  handleClose,
  mscFeeCounter,
  setMscFeeCounter,
  setTotalWeight,
  totalWeight
}) => {
  const {
    formField: {
      duty,
      handlingFee,
      miscellaneousFeesOne,
      miscellaneousFeesThree,
      miscellaneousFeesOneNote,
      miscellaneousFeesThreeNote,
      miscellaneousFeesTwoNote,
      miscellaneousFeesTwo,
      importFee,
      wantToUseCalculator
    }
  } = Model
  console.log({ workorder })
  useEffect(() => {
    setTotalWeight(calculateTotalWeight(data))
  }, [data, setTotalWeight])
  return (
    <div className="flex flex-col gap-8 bg-white h-[95%] pt-16  overflow-y-auto overflow-x-hidden">
      {isLoading && <BackDropLoader />}

      <div className="p-4 flex flex-col gap-4">
        <div className="flex flex-row gap-2 items-center">
          <h3 className="font-semibold text-2xl capitalize">{`Update Workorder Fees (${PackageType})`}</h3>
          {totalWeight > 0 && (
            <h4 className="font-semibold text-md">{`Weight : ${totalWeight} lbs`}</h4>
          )}
        </div>

        {totalWeight > 0 ? (
          <div className="">
            <Formik
              enableReinitialize={true}
              initialValues={{
                [wantToUseCalculator.name]: workorder?.want_to_use_calculator,
                [importFee.name]: workorder?.import_fee
                  ? workorder?.import_fee
                  : "",
                [handlingFee.name]: workorder?.handling_fee
                  ? workorder?.handling_fee
                  : "",
                [duty.name]: workorder?.duty ? workorder?.duty : "",
                [miscellaneousFeesOne.name]: workorder?.miscellaneous_fees_one
                  ? workorder?.miscellaneous_fees_one
                  : "",
                [miscellaneousFeesTwo.name]: workorder?.miscellaneous_fees_two
                  ? workorder?.miscellaneous_fees_two
                  : "",
                [miscellaneousFeesThree.name]:
                  workorder?.miscellaneous_fees_three
                    ? workorder?.miscellaneous_fees_three
                    : "",
                [miscellaneousFeesOneNote.name]:
                  workorder?.miscellaneous_fees_one_note
                    ? workorder?.miscellaneous_fees_one_note
                    : "",
                [miscellaneousFeesTwoNote.name]:
                  workorder?.miscellaneous_fees_two_note
                    ? workorder?.miscellaneous_fees_two_note
                    : "",
                [miscellaneousFeesThreeNote.name]:
                  workorder?.miscellaneous_fees_three_note
                    ? workorder?.miscellaneous_fees_three_note
                    : ""
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => onFormSubmit(values, actions)}
            >
              {(formik) => {
                // console.log({ formik })
                return (
                  <form>
                    <div className="flex">
                      <div className="basis-[70%] flex flex-col gap-6">
                        <div className="flex flex-col gap-3">
                          <FeeForm
                            mscFeeCounter={mscFeeCounter}
                            setMscFeeCounter={setMscFeeCounter}
                            packageType={PackageType}
                            totalWeight={totalWeight}
                          />
                          <button
                            type="submit"
                            onClick={formik.handleSubmit}
                            className="self-end bg-blue-500 text-white px-3 py-[0.3em] rounded-md  hover:bg-blue-700 font-semibold"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )
              }}
            </Formik>
          </div>
        ) : (
          <h1>Please add some packages first</h1>
        )}
      </div>
    </div>
  )
}

export default FeeDialog
