import React from "react";
// import { CircularIconButton } from "../formElements/buttons"
// import { TextField } from "../formElements/oecDashboard/table"
import { IconTextButton } from "../formElements";

const TableTopHeader = ({
  refreshBtn = false,
  addBtn = false,
  addBtnFee = false,
  updateBtn = false,

  twoSearchField = false,
  selectedRow,
  addBtnTitle,
  addBtnFeeTitle,
  updateBtnTitle,
  onUpdateBtnClick,
  onRefreshClick,
  onAddClick,
  onAddFeeClick,

  setQuery,
  queryOne,
  queryTwo,
  setQueryTwo,
  searchTitleTwo,
  searchTitle,
}) => {
  return (
    <div className="flex items-center py-2 px-4 justify-between">
      {twoSearchField ? (
        <div className="flex flex-row gap-3">
          <div className="relative w-64">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              value={queryOne}
              type="search"
              id="default-search"
              className="block w-full p-2 ps-10 text-sm text-gray-900 font-semibold border-gray-300 rounded-md bg-gray-50 focus:ring-blue-500 focus:border-blue-500 outline-none border-2"
              placeholder={searchTitle ? searchTitle : "Search"}
              onChange={(e) => {
                setQuery(e.target.value);
                setQueryTwo("");
              }}
            />
          </div>
          <div className="relative w-64">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              value={queryTwo}
              type="search"
              id="default-search"
              className="block w-full p-2 ps-10 text-sm text-gray-900 font-semibold border-gray-300 rounded-md bg-gray-50 focus:ring-blue-500 focus:border-blue-500 outline-none border-2"
              placeholder={searchTitleTwo ? searchTitleTwo : "Search"}
              onChange={(e) => {
                setQuery("");
                setQueryTwo(e.target.value);
              }}
            />
          </div>
        </div>
      ) : (
        <div className="relative w-64">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            value={queryOne}
            type="search"
            id="default-search"
            className="block w-full p-2 ps-10 text-sm text-gray-900 font-semibold border-gray-300 rounded-md bg-gray-50 focus:ring-blue-500 focus:border-blue-500 outline-none border-2"
            placeholder={searchTitle ? searchTitle : "Search"}
            onChange={(e) => {
              setQuery(e.target.value);
              // setQueryTwo(e.target.value);
            }}
          />
        </div>
      )}
      <div className="flex items-center gap-2">
      

        {selectedRow?.length > 0 && updateBtn && (
          <IconTextButton
            type={"update"}
            title={updateBtnTitle}
            onClick={onUpdateBtnClick}
          />
        )}

        {addBtnFee && (
          <IconTextButton
            type={"fee"}
            title={addBtnFeeTitle}
            onClick={onAddFeeClick}
          />
        )}
        {addBtn && (
          <IconTextButton
            type={"add"}
            title={addBtnTitle ? addBtnTitle : "Add"}
            onClick={onAddClick}
          />
        )}
          {refreshBtn && (
          <IconTextButton
            type={"refresh"}
            title={"Refresh"}
            onClick={onRefreshClick}
          />
        )}
        {/* {true && <Button type={"add"} title={"Add"} onClick={onAddClick} />} */}
      </div>
    </div>
  );
};

export default TableTopHeader;
