import React, { useState } from "react"
import Label from "./Label"
import ErrorMessage from "./ErrorMessage"
import { useField } from "formik"
import SelectDropDown from "react-select"
import "./index.css"
// d1d5db
const colourStyles = {
  control: (base, state) => ({
    ...base,
    fontWeight: 500,
    fontSize: "1rem",

    background: "#f9fafb",
    // match with the menu
    // borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // // Overwrittes the different states of border
    borderColor: state.isFocused ? "#3f4042" : "#d1d5db",
    // // Removes weird border around container
    // boxShadow: state.isFocused ? null : null,
    border: state.isFocused && "2px solid",
    // This line disable the blue border
    boxShadow: "none",
    "&:hover": {
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "red" : "blue"
    }
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    marginTop: 0,
    padding: 5
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      marginTop: 1,
      fontSize: "0.9rem",
      fontWeight: 500,
      backgroundColor: "white",
      color: "black",
      // ...(isSelected && {
      //   backgroundColor: "red",
      //   color: "white"
      // }),
      ...(isFocused && {
        backgroundColor: "#6666ff",
        color: "white"
      }),
      // backgroundColor: isDisabled ? 'red' : 'blue',
      // color: '#FFF',
      // cursor: isDisabled ? 'not-allowed' : 'default',
      "&:hover": {
        backgroundColor: "#6666ff",
        color: "white"
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      }
    }
  },
  placeholder: (base) => ({
    ...base,
    fontWeight: 400,
    fontSize: "0.95rem",
    color: "#9ca3af", // Light gray
  }),
}

const Select = ({ options, placeholder, fieldlabel, ...props }) => {
  // const [selectedOption, setSelectedOption] = useState(null)
  const [field, { error, touched, value }, { setValue }] = useField(props)

  const onSelectChange = ({ value }) => {
    setValue(value)
  }

  // console.log({ value })

  // let value = null

  return (
    <div className="flex flex-col items-start gap-[0.1em] w-[100%]">
      <Label label={fieldlabel} />
      <div className="flex flex-col w-[100%]">
        <SelectDropDown
          value={options.filter((item) => item.value === value)}
          placeholder={placeholder}
          defaultValue={null}
          onChange={onSelectChange}
          options={options}
          styles={colourStyles}
        />
        <ErrorMessage error={error} touched={touched} />
      </div>
    </div>
  )
}

export default Select
