import { useField } from "formik";
import React from "react";

const PackageCheck = (props) => {
  const [field, { error, touched }, { setValue }] = useField(props);
  // Update the checked attribute based on the field.value
  const isCheckedOff = field.value === "checked-off";
  const isNotCheckedOff = field.value === "not-checked-off";
  return (
    <div className="flex flex-row gap-10 items-center">
      <div className="flex items-center">
        <input
          id="default-radio-1"
          type="radio"
          value="checked-off"
          name={field.name}
          checked={isCheckedOff}
          onChange={() => setValue("checked-off")}
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        <label
          htmlFor="default-radio-1"
          className="ml-2 text-sm font-medium text-gray-900"
        >
          Checked Off
        </label>
      </div>
      <div className="flex items-center">
        <input
          id="default-radio-2"
          type="radio"
          value="not-checked-off"
          name={field.name}
          checked={isNotCheckedOff}
          onChange={() => setValue("not-checked-off")}
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        <label
          htmlFor="default-radio-2"
          className="ml-2 text-sm font-medium text-gray-900"
        >
          Not Checked Off
        </label>
      </div>
    </div>
  );
};

export default PackageCheck;
