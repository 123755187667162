import React from "react";
import { DateFormat } from "../../../../utils/format";
import { BackDropLoader } from "../../../common/loader";
import { IoMdArrowBack, IoMdRefresh } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Dialog } from "../../../common/Dialog";
import GenerateInvoice from "./GenerateInvoice";
import EmailDialog from "../../../common/Dialog/EmailDialog";
import WOPackageTable from "./WOPackageTable";
import { MdMarkEmailRead, MdOutlineMail } from "react-icons/md";
import { FaFileInvoice } from "react-icons/fa6";
import EmailDialogReceived from "../../../common/Dialog/EmailDialogReceived";
import LoadingButton from "../../../common/formElements/LoadingButton";
import { IoCloudDownloadOutline } from "react-icons/io5";

const SingleWorkOrder = ({
  data,
  isFetching,
  isLoading,
  isSuccess,

  onEmailSentPickup,
  open,
  refetch,
  id,
  emailDialogReadyForPickup,
  setEmailDialogReadyForPickup,
  closeDialog,
  openDialog,
  emailRequestQueryReadyForPickup,

  setEmailDialogReceived,
  emailDialogReceived,
  emailRequestQueryReceived,
  onEmailSentReceived,
  onHandleInvoice,
  invoiceLoading,
  invoiceData,
  onHandleDownloadInvoice,
}) => {
  const navigate = useNavigate();

  if (isLoading || isFetching) {
    return (
      <div className="flex flex-col gap-8 bg-white shadow-lg p-4">
        {(isLoading || isFetching) && <BackDropLoader />}

        <div className="self-start flex items-center gap-1 text-blue-500 ">
          <IoMdArrowBack size={15} />
          <div
            onClick={() => {
              navigate("/dashboard/work-orders");
            }}
            className="hover:border-b-2 cursor-pointer border-blue-600"
          >
            Back to List of Work Orders
          </div>
        </div>
      </div>
    );
  }
  if (isSuccess) {
    // const isShippedAndCheckedInOrReceived =
    //   data?.status === "shipped" &&
    //   (data?.cargoAvailableStatus === "checkInComplete" ||
    //     data?.cargoAvailableStatus === "receivedAtWarehouse");
    const isShippedAndCheckedIn =
      data?.status === "shipped" &&
      data?.cargoAvailableStatus === "checkInComplete";
    const isShippedAndReceived =
      data?.status === "shipped" &&
      data?.cargoAvailableStatus === "receivedAtWarehouse";
    const isEmailSentReadyForPickup = data?.is_email_sended_ready_for_pickup;
    const isEmailSentReceived = data?.is_email_sended_received;
    return data ? (
      <div className="flex flex-col gap-8 bg-white shadow-lg p-4 ">
        {/* {invoiceDialog && (
          <Dialog
            isOpen={open}
            handleClose={() => {
              closeDialog();
              setInoviceDialog(false);
            }}
          >
            <GenerateInvoice data={data} />
          </Dialog>
        )} */}
        {emailDialogReadyForPickup && (
          <EmailDialog
            open={open}
            handleClose={() => {
              closeDialog();
              setEmailDialogReadyForPickup(false);
            }}
            onSubmitForm={onEmailSentPickup}
            isLoading={emailRequestQueryReadyForPickup.isLoading}
          />
        )}
        {emailDialogReceived && (
          <EmailDialogReceived
            open={open}
            handleClose={() => {
              closeDialog();
              setEmailDialogReceived(false);
            }}
            onSubmitForm={onEmailSentReceived}
            isLoading={emailRequestQueryReceived.isLoading}
          />
        )}

        {/* {isLoadingUpdateMt && <BackDropLoader />} */}
        <div className="self-start flex items-center gap-1 text-blue-500 ">
          <IoMdArrowBack size={15} />
          <div
            onClick={() => {
              navigate("/dashboard/work-orders");
            }}
            className="hover:border-b-2 cursor-pointer border-blue-600"
          >
            Back to List of Work Orders
          </div>
        </div>
        <div className="p-4 flex flex-col gap-4 ">
          <div className="flex flex-row  items-center justify-between ">
            <div className="flex gap-2 flex-row items-center">
              <h3 className="font-semibold text-2xl">View Work Order</h3>
              <div
                onClick={() => {
                  navigate(`/dashboard/work-orders/edit/${id}`);
                }}
                className="flex items-center gap-1 text-blue-500 hover:border-b-2 cursor-pointer border-blue-600"
              >
                Edit
              </div>
            </div>
            <div className=" flex flex-row gap-3">
              <button
                onClick={() => {
                  if (isEmailSentReceived || !isShippedAndReceived) return;
                  setEmailDialogReceived(true);
                  openDialog();
                }}
                className={`font-semibold text-sm p-2 rounded-lg flex flex-row items-center gap-2 text-white    ${
                  isEmailSentReceived
                    ? "cursor-not-allowed bg-slate-400"
                    : isShippedAndReceived
                    ? "hover:bg-green-600 bg-green-500"
                    : " bg-green-500 cursor-not-allowed "
                } ${
                  !isShippedAndReceived && !isEmailSentReceived && "opacity-70"
                }`}
              >
                {data?.is_email_sended_received ? (
                  <MdMarkEmailRead size={18} />
                ) : (
                  <MdOutlineMail size={18} />
                )}
                {data?.is_email_sended_received
                  ? "Received At Warehouse Email Successfully Sent"
                  : "Send Received At Warehouse Email"}
              </button>
              <button
                onClick={() => {
                  if (isEmailSentReadyForPickup || !isShippedAndCheckedIn)
                    return;
                  setEmailDialogReadyForPickup(true);
                  openDialog();
                }}
                className={`font-semibold text-sm p-2 rounded-lg flex flex-row items-center gap-2 text-white    ${
                  isEmailSentReadyForPickup
                    ? "cursor-not-allowed bg-slate-400"
                    : isShippedAndCheckedIn
                    ? "hover:bg-orange-600 bg-orange-500"
                    : " bg-orange-500 cursor-not-allowed "
                } ${
                  !isShippedAndCheckedIn &&
                  !isEmailSentReadyForPickup &&
                  "opacity-80"
                }`}
              >
                {data?.is_email_sended_ready_for_pickup ? (
                  <MdMarkEmailRead size={18} />
                ) : (
                  <MdOutlineMail size={18} />
                )}
                {data?.is_email_sended_ready_for_pickup
                  ? "Ready for Pick up Email Successfully Sent"
                  : "Send Ready for Pick up Email"}
              </button>

              <LoadingButton
                loading={invoiceLoading}
                icon={<FaFileInvoice size={18} />}
                onClick={onHandleInvoice}
              >
                {" "}
                {invoiceData ? "Re-Generate Invoice " : "Generate Invoice"}
              </LoadingButton>
              {invoiceData && (
                <LoadingButton
                  icon={<IoCloudDownloadOutline size={22} />}
                  onClick={onHandleDownloadInvoice}
                  loading={invoiceLoading}
                >
                  Download Invoice
                </LoadingButton>
              )}

              <button
                onClick={() => refetch()}
                className={`font-semibold text-sm p-2 rounded-lg flex flex-row items-center gap-2  bg-gray-200 hover:bg-gray-300`}
              >
                <IoMdRefresh size={20} />
                Refresh
              </button>
            </div>
          </div>
          <div className="flex flex-row gap-8">
            <div className="border-2 w-[50%] flex flex-col capitalize font-semibold">
              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">Type</div>
                <div className="border-2 basis-[50%] ps-2 py-1">
                  {data?.type}
                </div>
              </div>

              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">Title</div>
                <div className="border-2 basis-[50%] ps-2 py-1">
                  {data?.title}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">Email</div>
                <div className="border-2 basis-[50%] ps-2 py-1 lowercase">
                  {data?.email}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">
                  Tracking Id
                </div>
                <div className="border-2 basis-[50%] ps-2 py-1">
                  {data?.trackingId}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">
                  Cargo Available Status
                </div>
                <div className="border-2 basis-[50%] ps-2 py-1 flex">
                  {data?.cargoAvailableStatus === "inProcessOfCheckIn" ? (
                    <p className="bg-slate-200 text-slate-700 rounded-md px-2">
                      In Process Of Check In
                    </p>
                  ) : data?.cargoAvailableStatus === "receivedAtWarehouse" ? (
                    <p className=" text-blue-700 bg-blue-200 rounded-md px-2">
                      Received At Warehouse
                    </p>
                  ) : (
                    <p className=" text-blue-700 bg-blue-200 rounded-md px-2">
                      Ready for Pick up
                    </p>
                  )}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">Status</div>
                <div className="border-2 basis-[50%] ps-2 py-1 flex">
                  {data?.status === "placed" ? (
                    <p className="bg-orange-200 text-orange-700 px-2 rounded-md">
                      Placed
                    </p>
                  ) : data?.status === "shipped" ? (
                    <p className="bg-emerald-200 text-emerald-700 px-2 rounded-md">
                      Shipped
                    </p>
                  ) : (
                    <p className="bg-blue-200 text-blue-700 px-2 rounded-md">
                      Delivered
                    </p>
                  )}
                </div>
              </div>
              {data?.last_free_day && (
                <div className="flex flex-row">
                  <div className="border-2 basis-[50%] ps-2 py-1">
                    Last Free Date
                  </div>
                  <div className="border-2 basis-[50%] ps-2 py-1">
                    {DateFormat(data?.last_free_day, true)}
                  </div>
                </div>
              )}

              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">
                  Arrival Date
                </div>
                <div className="border-2 basis-[50%] ps-2 py-1">
                  {DateFormat(data?.arrivalDate, true)}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">
                  Departure Date
                </div>
                <div className="border-2 basis-[50%] ps-2 py-1">
                  {DateFormat(data?.departureDate, true)}
                </div>
              </div>

              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">Weight</div>
                <div className="border-2 basis-[50%] ps-2 py-1">
                  {data?.total_weight > 0
                    ? `${data?.total_weight} lbs`
                    : "0 lbs"}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">
                  Cost Calculator Used
                </div>
                <div className="border-2 basis-[50%] ps-2 py-1">
                  {data?.want_to_use_calculator ? "Yes" : "No"}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">Import Fee</div>
                <div className="border-2 basis-[50%] ps-2 py-1">
                  {`${data?.import_fee}  ${data?.import_fee ? "USD" : "0 USD"}`}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">
                  Handling Fee
                </div>
                <div className="border-2 basis-[50%] ps-2 py-1">
                  {`${data?.handling_fee}  ${
                    data?.handling_fee ? "USD" : "0 USD"
                  }`}
                </div>
              </div>
              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">Duty</div>
                <div className="border-2 basis-[50%] ps-2 py-1">
                  {`${data?.duty} ${data?.duty ? "USD" : "0 USD"}`}
                </div>
              </div>
              {data?.miscellaneous_fees_one && (
                <div className="flex flex-row">
                  <div className="border-2 basis-[50%] ps-2 py-1">
                    Miscellaneous Fees 1
                  </div>
                  <div className="border-2 basis-[50%] ps-2 py-1 flex flex-col">
                    {data?.miscellaneous_fees_one ? (
                      <>
                        <div>{`${data?.miscellaneous_fees_one} USD`} </div>
                        <div className="bg-blue-50 m-2 rounded-sm p-2">
                          {`${data?.miscellaneous_fees_one_note}`}{" "}
                        </div>
                      </>
                    ) : (
                      "0 USD"
                    )}
                  </div>
                </div>
              )}
              {data?.miscellaneous_fees_two && (
                <div className="flex flex-row">
                  <div className="border-2 basis-[50%] ps-2 py-1">
                    Miscellaneous Fees 2
                  </div>
                  <div className="border-2 basis-[50%] ps-2 py-1 flex flex-col">
                    {data?.miscellaneous_fees_two ? (
                      <>
                        <div>{`${data?.miscellaneous_fees_two} USD`} </div>
                        <div className="bg-blue-50 m-2 rounded-sm p-2">
                          {`${data?.miscellaneous_fees_two_note}`}{" "}
                        </div>
                      </>
                    ) : (
                      "0 USD"
                    )}
                  </div>
                </div>
              )}
              {data?.miscellaneous_fees_three && (
                <div className="flex flex-row">
                  <div className="border-2 basis-[50%] ps-2 py-1">
                    Miscellaneous Fees 3
                  </div>
                  <div className="border-2 basis-[50%] ps-2 py-1 flex flex-col">
                    {data?.miscellaneous_fees_three ? (
                      <>
                        <div>{`${data?.miscellaneous_fees_three} USD`} </div>
                        <div className="bg-blue-50 m-2 rounded-sm p-2">
                          {`${data?.miscellaneous_fees_three_note}`}{" "}
                        </div>
                      </>
                    ) : (
                      "0 USD"
                    )}
                  </div>
                </div>
              )}

              <div className="flex flex-row">
                <div className="border-2 basis-[50%] ps-2 py-1">Total Cost</div>
                <div className="border-2 basis-[50%] ps-2 py-1 flex flex-col">
                  {data?.total_cost ? (
                    <div>{`${data?.total_cost} USD`} </div>
                  ) : (
                    "0 USD"
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-6">
              <h2 className="flex text-xl font-semibold">
                Work Order Pictures
              </h2>
              <div className="flex flex-row gap-4">
                {data?.pics_url?.length > 0 ? (
                  data?.pics_url.map((file, index) => {
                    return (
                      <div className="flex h-32 w-32" key={index}>
                        <img
                          className="w-full  h-full rounded-md object-cover"
                          src={`${process.env.REACT_APP_URL}/uploads/images/${file}`}
                          alt="pic"
                        />
                      </div>
                    );
                  })
                ) : (
                  <div
                    onClick={() => {
                      navigate(`/dashboard/work-orders/edit/${id}`);
                    }}
                    className="flex items-center gap-1 text-blue-500 hover:border-b-2 cursor-pointer border-blue-600"
                  >
                    Add Pictures
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <WOPackageTable
            refetch={refetch}
            workorder={data}
            id={id}
            data={data?.packages}
            type={data?.type}
          />
        </div>
      </div>
    ) : (
      <div className="flex flex-col gap-8 bg-white shadow-lg p-4 h-96">
        <div className="self-start flex items-center gap-1 text-blue-500 ">
          <IoMdArrowBack size={15} />
          <div
            onClick={() => {
              navigate("/dashboard/work-orders");
            }}
            className="hover:border-b-2 cursor-pointer border-blue-600"
          >
            Back to List of Work Orders
          </div>
        </div>
        No work Order found
      </div>
    );
  }
  if (!isSuccess && !isLoading) {
    return (
      <div className="flex flex-col gap-8 bg-white shadow-lg p-4 h-96">
        <div className="self-start flex items-center gap-1 text-blue-500 ">
          <IoMdArrowBack size={15} />
          <div
            onClick={() => {
              navigate("/dashboard/work-orders");
            }}
            className="hover:border-b-2 cursor-pointer border-blue-600"
          >
            Back to List of Work Orders
          </div>
        </div>
        No work Order found
      </div>
    );
  }
};

export default SingleWorkOrder;
