import React, { useEffect, useMemo, useState } from "react";
import {
  TableBody,
  TableFooter,
  TableHeader,
  TableTopHeader,
} from "../../../common/table";
import {
  useButtonActions,
  useDebounce,
  useDialogModel,
  useToast,
} from "../../../../hooks";
import useTableData from "../../../../hooks/useTableData";
import { columns } from "./config";
import {
  useDeleteWorkOrderMutation,
  useGetWorkordersQuery,
  useDeleteThreeMonthsOlderWorkOrderPackageMutation,
  useUpdateBulkWorkOrderMutation,
} from "../../../../app/services/workOrderSlice";
import {
  Dialog,
  DeleteDialog,
  DeleteByDateDialog,
} from "../../../common/Dialog";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Actions from "./Actions";
import Filters from "./Filters";
import { BackDropLoader } from "../../../common/loader";
import { MdDeleteOutline } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Drawer from "./UpdateDrawer/Drawer";
import UpdateContent from "./UpdateDrawer/UpdateContent";

const WorkOrderTable = () => {
  const [isOpenDrawer, setIsOpenDrawer] = React.useState(false);

  const [date, setDate] = useState(() => {
    const today = new Date();
    today.setMonth(today.getMonth() - 3);
    return today;
  });
  const onDateChange = (date) => {
    setDate(date);
  };
  const [selectedRows, setSelectedRows] = useState([]);
  const [filters, setFilters] = useState({
    type: null,
    status: null,
    cargoAvailableStatus: null,
  });
  // table container
  const [open, openDialog, closeDialog] = useDialogModel();
  const navigate = useNavigate();
  const {
    currentPage,
    pageSize,
    query,
    goToPage,
    setItemsPerPage,
    setSearchQuery,
    setSortQuery,
    sortQuery,
  } = useTableData();

  const {
    currentItem,
    startDeleting,
    startDeletingByDate,

    isDeleting,
    isDeletingByDate,
  } = useButtonActions();

  const { state } = useLocation();
  window.history.replaceState({}, document.title);
  const debouncedSearchTerm = useDebounce(query, 200);

  const { isLoading, isFetching, refetch, error, data } = useGetWorkordersQuery(
    {
      limit: pageSize,
      page: currentPage,
      sort: `${sortQuery.dir === "dsc" ? "-" : ""}${sortQuery.field}`,
      type: filters.type,
      status: filters.status,
      cargoAvailableStatus: filters.cargoAvailableStatus,
      debouncedSearchTerm,
      searchField: "trackingId",
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
      refetchOnReconnect: true,
    }
  );

  const [deleteRequest, { isLoading: isLoadingDeleteMt }] =
    useDeleteWorkOrderMutation();

  const [deleteByDateRequest, { isLoading: isLoadingDeleteByDateMt }] =
    useDeleteThreeMonthsOlderWorkOrderPackageMutation();
  const [updateBulkRequest, { isLoading: isLoadingUpdateBulkWorkOrder }] =
    useUpdateBulkWorkOrderMutation();

  const getFilteredData = useMemo(() => {
    if (isLoading || isFetching) return [];
    if (data?.data?.length > 0) {
      const today = new Date();
      today.setMonth(today.getMonth() - 3);
      setDate(today);
      setSelectedRows([]);
      return data?.data?.filter((item) => {
        return item;
      });
    } else {
      return [];
    }
  }, [isLoading, isFetching, data?.data]);

  const onRefreshClick = () => {
    refetch();
    const today = new Date();
    today.setMonth(today.getMonth() - 3);
    setDate(today);
  };
  const onAddClick = () => {
    navigate("/dashboard/work-orders/new");
  };

  const onEditItemClick = (item) => {
    navigate(`/dashboard/work-orders/edit/${item._id}`);
  };
  const onDeleteItemClick = (item) => {
    startDeleting(item);
    openDialog();
  };
  const onViewItemClick = (item) => {
    navigate(`/dashboard/work-orders/view/${item._id}`);
  };

  const onDeleteDialogClick = async () => {
    try {
      const response = await deleteRequest({ id: currentItem._id }).unwrap();
      if (response.success) {
        closeDialog();
        toast.success(response?.message);
      }
    } catch (error) {
      toast.error(
        error?.data?.message ? error?.data?.message : "No server response",
        {
          closeOnClick: true,
          toastId: 12,
          autoClose: 1000,
        }
      );
      closeDialog();
    }
  };
  const onDeleteByDateDialogClick = async () => {
    try {
      const response = await deleteByDateRequest({ date }).unwrap();
      if (response.success) {
        closeDialog();
        toast.success(response?.message);
      }
    } catch (error) {
      toast.error(
        error?.data?.message ? error?.data?.message : "No server response",
        {
          closeOnClick: true,
          toastId: 12,
          autoClose: 1000,
        }
      );
      closeDialog();
    }
  };

  const handleRowSelect = (rowId) => {
    setSelectedRows((ids) => {
      const updateSelectedRows = selectedRows.includes(rowId)
        ? selectedRows.filter((id) => id !== rowId)
        : [...ids, rowId];
      return updateSelectedRows;
    });
  };
  const onSort = (columnName) => {
    setSortQuery((prev) => ({
      field: columnName,
      dir:
        prev.field === columnName
          ? prev.dir === "default"
            ? "asc"
            : prev.dir === "asc"
            ? "dsc"
            : prev.dir === "dsc" && "asc"
          : "asc",
    }));
  };

  const onSubmitBulkUpdate = async (values, actions) => {
    try {
      const response = await updateBulkRequest({
        ids: selectedRows,
        cargoAvailableStatus: values?.cargoAvailableStatus,
        status: values?.status,
        arrivalDate: values?.arrivalDate,
        departureDate: values?.departureDate,
        last_free_day: values?.last_free_day,
      }).unwrap();
      if (response.success) {
        setIsOpenDrawer(false);
        actions.resetForm();
        toast.success(response?.message);
      }
    } catch (error) {
      toast.error(
        error?.data?.message ? error?.data?.message : "No server response",
        {
          closeOnClick: true,
          toastId: 12,
          autoClose: 1000,
        }
      );
    }
  };

  useEffect(() => {
    if (state?.success) {
      toast.success(state.message);
    }
  }, [state]);

  useEffect(() => {
    if (error) {
      if (error?.status === 403) {
        localStorage.clear({});
        navigate("/login", { state: { expired: true } });
      }
    }
  }, [error, navigate]);

  return (
    <div className=" flex flex-col gap-1 bg-white shadow-lg">
      <Drawer isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer}>
        <UpdateContent
          isLoading={isLoadingUpdateBulkWorkOrder}
          onSubmitBulkUpdate={onSubmitBulkUpdate}
          setIsOpen={setIsOpenDrawer}
        />
      </Drawer>
      <div className="flex items-center flex-row gap-3 justify-end p-4">
        <button
          onClick={() => {
            startDeletingByDate(null);
            openDialog();
          }}
          className="bg-red-500 hover:bg-red-600 flex flex-row items-center gap-2 px-4 py-[0.35em]  text-white rounded-md"
        >
          <MdDeleteOutline size={22} />{" "}
          <span>
            {" "}
            Delete work orders equal and older than by Departure Date
          </span>
        </button>
        <DatePicker
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-[3px] block w-full p-[0.52em] font-semibold text-[1rem] focus:outline-gray-800"
          selected={date}
          onChange={onDateChange}
        />
      </div>
      <div className="flex items-center  justify-end gap-3">
        {selectedRows.length > 0 && (
          <button
            className="bg-blue-500 text-white rounded-md px-2 py-2"
            onClick={() => setIsOpenDrawer(true)}
          >
            Update Status and Dates
          </button>
        )}
        <Filters setFilters={setFilters} filters={filters} />
      </div>

      <ToastContainer autoClose={1000} />
      {isDeleting && (
        <DeleteDialog
          isLoading={isLoadingDeleteMt}
          onSubmitForm={onDeleteDialogClick}
          handleClose={closeDialog}
          open={open}
        />
      )}
      {isDeletingByDate && (
        <DeleteByDateDialog
          date={date}
          isLoading={isLoadingDeleteByDateMt}
          onSubmitForm={onDeleteByDateDialogClick}
          handleClose={closeDialog}
          open={open}
        />
      )}
      <TableTopHeader
        onRefreshClick={onRefreshClick}
        searchTitle={"Tracking id"}
        addBtn={true}
        queryOne={query}
        addBtnTitle={"Create Work Order"}
        refreshBtn={true}
        onAddClick={onAddClick}
        setQuery={setSearchQuery}
      />
      {/* table wrapper */}
      <div className="flex flex-col min-h-[25em] justify-between px-2">
        {/* table head and body wrapper */}
        <table className="table-fixed border-collapse w-[100%] max-w-[100%] whitespace-nowrap">
          <TableHeader
            columns={columns}
            onSort={onSort}
            sortQuery={sortQuery}
          />
          <TableBody
            showEditButton={true}
            showDeleteButton={true}
            showViewButton={true}
            onViewItem={onViewItemClick}
            onEditItem={onEditItemClick}
            onDeleteItem={onDeleteItemClick}
            columns={columns}
            currentTableData={getFilteredData}
            isLoading={isLoading || isFetching}
            // isLoading={true}
            selectedRows={selectedRows} // Pass selected rows state
            handleRowSelect={handleRowSelect}
          />
        </table>
        <TableFooter
          isLoading={isLoading || isFetching}
          currentPage={currentPage}
          pageSize={pageSize}
          currentTableData={getFilteredData}
          setCurrentPage={goToPage}
          setPageSize={setItemsPerPage}
          totalCount={data?.totalCount}
        />
      </div>
    </div>
  );
};

export default WorkOrderTable;
