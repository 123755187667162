import React, { useCallback, useState } from "react";

const useTableData = () => {
  const [tableState, setTableState] = useState({
    currentPage: 1,
    pageSize: 10,
    query: "",
    sortQuery: {
      field: "updatedAt",
      dir: "asc",
    },
  });

  const updateTableState = useCallback((updates) => {
    setTableState((prevState) => ({
      ...prevState,
      ...updates,
    }));
  }, []);

  const goToPage = useCallback((page) => {
    updateTableState({ currentPage: page });
  }, [updateTableState]);

  const setItemsPerPage = useCallback((size) => {
    updateTableState({ pageSize: size });
  }, [updateTableState]);

  const setSearchQuery = useCallback((newQuery) => {
    updateTableState({ query: newQuery });
  }, [updateTableState]);

  const setSortQuery = useCallback((newQuery) => {
    updateTableState({
      sortQuery: newQuery,
      currentPage: 1, // Reset to first page when sorting changes
    });
  }, [updateTableState]);

  return {
    ...tableState,
    goToPage,
    setItemsPerPage,
    setSearchQuery,
    setSortQuery,
  };
};

export default useTableData;
