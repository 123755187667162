import React from "react";
import { IoMdRefresh } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";
import { FiMinus } from "react-icons/fi";
import { VscEdit } from "react-icons/vsc";
import { MdFavoriteBorder } from "react-icons/md";
import { FaPlaneCircleCheck } from "react-icons/fa6";
const IconTextButton = ({ title, type, ...props }) => {
  return (
    <button
      {...props}
      className={`bg-blue-400 font-semibold text-sm p-2 rounded-lg flex flex-row items-center gap-2
      ${type === "fee" ? "bg-slate-500 text-white hover:bg-slate-600" : ""}
      ${type === "fav" ? "bg-cyan-600 text-white hover:bg-cyan-700" : ""}
      ${type === "check" ? "bg-lime-600 text-white hover:bg-lime-700" : ""}
      ${type === "checkremove" ? "bg-lime-600 text-white hover:bg-lime-700" : ""}
      ${type === "favremove" ? "bg-cyan-600 text-white hover:bg-cyan-700" : ""}
      ${type === "remove" ? "bg-slate-500 text-white hover:bg-slate-600" : ""}
      ${type === "add" ? "bg-blue-600 text-white hover:bg-blue-700" : ""}
      ${type === "update" ? "bg-blue-600 text-white hover:bg-blue-700" : ""}
      ${type === "refresh" ? "bg-gray-200 hover:bg-gray-300" : ""}
      `}
    >
      {type === "refresh" && <IoMdRefresh size={20} />}
      {type === "add" && <IoMdAdd size={20} />}
      {type === "update" && <VscEdit size={20} />}
      {type === "checkremove" && <FiMinus size={20} />}
      {type === "check" && <FaPlaneCircleCheck  size={20} />}
      {type === "fee" && <VscEdit size={20} />}
      {type === "fav" && <MdFavoriteBorder  size={20} />}
      {type === "favremove" && <FiMinus   size={20} />}
      {type === "remove" && <FiMinus size={20} />}
      <div>{title}</div>
    </button>
  );
};

export default IconTextButton;
