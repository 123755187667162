import React, { useEffect, useMemo, useState } from "react";
import {
  TableBody,
  TableFooter,
  TableHeader,
  TableTopHeader,
} from "../../../common/table";
import { useButtonActions, useDialogModel } from "../../../../hooks";
import useTableData from "../../../../hooks/useTableData";
import { packagecolumns } from "./config";
import {
  useAddWorkOrderPackageMutation,
  useDeleteWorkOrderPackageMutation,
  useUpdateBulkWorkOrderPackagesMutation,
  useUpdateWorkOrderFeeMutation,
  useUpdateWorkOrderPackageCheckOffMutation,
  useUpdateWorkOrderPackageFavMutation,
  useUpdateWorkOrderPackageMutation,
} from "../../../../app/services/workOrderSlice";
import { Dialog, DeleteDialog } from "../../../common/Dialog";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddPackageDialog from "./addPackage/AddPackageDialog";
import { textSorting } from "../../../../utils/sorting";
import EditWorkOrderPackage from "./edit/EditWorkOrderPackage";
import FeeDialog from "./importAndHandlingFee/FeeDialog";
import {
  calculateTotalCost,
  calculateTotalWeight,
} from "../../../../utils/format";
import { useGetCostSettingQuery } from "../../../../app/services/costSetttingSlice";
import Drawer from "./UpdateDrawer/Drawer";
import UpdateContent from "./UpdateDrawer/UpdateContent";

const WOPackageTable = ({ id, workorder, data, type, refetch }) => {
  console.log({ id, workorder, data });
  const costsettingQureyResult = useGetCostSettingQuery({
    name: "shipping_cost",
  });
  const [isOpenDrawer, setIsOpenDrawer] = React.useState(false);

  const [totalWeight, setTotalWeight] = useState(0);
  const [selectedRow, setSelectedRow] = useState("");
  // console.log({ selectedRow });
  // table container
  const navigate = useNavigate();
  const [mscFeeCounter, setMscFeeCounter] = useState(0);
  const [addRequest, { isLoading: isLoadingAddMt }] =
    useAddWorkOrderPackageMutation();
  const [addFeeRequest, addFeeRequestQueryResult] =
    useUpdateWorkOrderFeeMutation();
  const [deleteRequest, { isLoading: isLoadingDeleteMt }] =
    useDeleteWorkOrderPackageMutation();
  const [updateRequest, { isLoading: isLoadingUpdateMt }] =
    useUpdateWorkOrderPackageMutation();
  // const [toogleFavRequest, favRequestQueryResult] =
  //   useUpdateWorkOrderPackageFavMutation();
  // const [toogleCheckRequest, checkOffRequestQueryResult] =
  //   useUpdateWorkOrderPackageCheckOffMutation();
  const [updateBulkPackagesRequest, updateBulkPackagesRequestQureyResult] =
    useUpdateBulkWorkOrderPackagesMutation();
  const [open, openDialog, closeDialog] = useDialogModel();
  const {
    currentPage,
    pageSize,
    query,
    goToPage,
    setItemsPerPage,
    setSearchQuery,
    setSortQuery,
    sortQuery,
  } = useTableData();

  const {
    currentItem,
    isEditing,
    isDeleting,
    startEditing,
    startDeleting,
    resetActions,
    isAdding,
    startAdding,
    isAddingFee,
    startAddingFee,
    startViewing,
    isViewing,
    isEditingBulk,
    startEditingBulk,
  } = useButtonActions();

  const getFilteredData = useMemo(() => {
    if (data?.length > 0) {
      return data?.filter((item) => {
        return item?.title?.toLowerCase().includes(query.toLowerCase());
      });
    } else {
      return [];
    }
  }, [data, query]);

  const getSortedData = useMemo(() => {
    if (sortQuery.field === "title" && sortQuery.dir === "asc") {
      return [...getFilteredData.sort((a, b) => textSorting(a.title, b.title))];
    } else if (sortQuery.field === "title" && sortQuery.dir === "dsc") {
      // console.log("sorting by lic no dsc");
      return [...getFilteredData.sort((a, b) => textSorting(b.title, a.title))];
    } else {
      return [...getFilteredData];
    }
  }, [getFilteredData, sortQuery]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return getFilteredData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, getFilteredData, pageSize]);

  const onAddClick = () => {
    startAdding();
    openDialog();
  };

  const onAddFeeClick = () => {
    startAddingFee();
    openDialog();
  };

  const onEditItemClick = (item) => {
    startEditing(item);
    openDialog();
    // navigate(`/dashboard/work-orders/edit/${item._id}`)
  };
  const onDeleteItemClick = (item) => {
    startDeleting(item);
    openDialog();
  };

  const onDeleteDialogClick = async () => {
    try {
      const response = await deleteRequest({
        id,
        pId: currentItem._id,
      }).unwrap();
      if (response.success) {
        closeDialog();
        resetActions();
        navigate(`/dashboard/work-orders/view/${id}`, { state: response });
      }
    } catch (error) {
      if (!toast.isActive(12, "error")) {
        toast.error(
          error?.data?.message ? error?.data?.message : "No server response",
          {
            closeOnClick: true,
            toastId: 12,
            autoClose: 1000,
          }
        );
      }
    }
  };
  const onAddFormSubmit = async (values, actions) => {
    // console.log({ workorder });
    let packageData = { ...values, cost: values?.cost.toString() };
    if (values.type === "other") {
      packageData = { ...packageData, width: "", height: "", length: "" };
    }
    if (values.wantToUseCalculator) {
      packageData = {
        ...packageData,
        customCostPerPound: "",
        costPerPound:
          workorder?.type === "consolidation"
            ? costsettingQureyResult.currentData?.value
            : "",
      };
    }
    try {
      const response = await addRequest({ id: id, ...packageData }).unwrap();
      if (response.success) {
        closeDialog();
        resetActions();
        actions.resetForm();
        navigate(`/dashboard/work-orders/view/${id}`, { state: response });
      }
    } catch (error) {
      toast.error(
        error?.data?.message ? error?.data?.message : "No server response",
        {
          containerId: "parentToast",
          closeOnClick: true,
          toastId: 12,
          autoClose: 1000,
        }
      );
    }
  };
  const onAddFeeFormSubmit = async (values, actions) => {
    const duty_cost = values.duty ? Number(values.duty) : 0;
    const import_fee_cost = values.import_fee ? Number(values.import_fee) : 0;
    const handling_fee_cost = values.handling_fee
      ? Number(values.handling_fee)
      : 0;
    let miscellaneous_fees_one_cost = 0;
    let miscellaneous_fees_two_cost = 0;
    let miscellaneous_fees_three_cost = 0;
    if (mscFeeCounter === 1) {
      miscellaneous_fees_one_cost = values.miscellaneous_fees_one
        ? Number(values.miscellaneous_fees_one)
        : 0;
    } else if (mscFeeCounter === 2) {
      miscellaneous_fees_one_cost = values.miscellaneous_fees_one
        ? Number(values.miscellaneous_fees_one)
        : 0;
      miscellaneous_fees_two_cost = values.miscellaneous_fees_two
        ? Number(values.miscellaneous_fees_two)
        : 0;
    } else if (mscFeeCounter === 3) {
      miscellaneous_fees_one_cost = values.miscellaneous_fees_one
        ? Number(values.miscellaneous_fees_one)
        : 0;
      miscellaneous_fees_two_cost = values.miscellaneous_fees_two
        ? Number(values.miscellaneous_fees_two)
        : 0;
      miscellaneous_fees_three_cost = values.miscellaneous_fees_three
        ? Number(values.miscellaneous_fees_three)
        : 0;
    }
    // console.log({
    //   miscellaneous_fees_one_cost,
    //   miscellaneous_fees_two_cost,
    //   miscellaneous_fees_three_cost,
    // });
    let totalCost =
      calculateTotalCost(data) +
      duty_cost +
      import_fee_cost +
      handling_fee_cost +
      miscellaneous_fees_one_cost +
      miscellaneous_fees_two_cost +
      miscellaneous_fees_three_cost;

    // console.log({ values });
    // console.log({
    //   miscellaneous_fees_one_cost,
    //   miscellaneous_fees_two_cost,
    //   miscellaneous_fees_three_cost,
    // });
    // console.log({ mscFeeCounter });
    // console.log({ totalCost });
    try {
      const response = await addFeeRequest({
        id: id,
        mscFeeCounter,
        total_weight: totalWeight,
        total_cost: totalCost.toString(),
        ...values,
        miscellaneous_fees_one:
          mscFeeCounter === 1 || mscFeeCounter === 2 || mscFeeCounter === 3
            ? values?.miscellaneous_fees_one
            : "0",
        miscellaneous_fees_one_note:
          mscFeeCounter === 1 || mscFeeCounter === 2 || mscFeeCounter === 3
            ? values?.miscellaneous_fees_one_note
            : "",
        miscellaneous_fees_two:
          mscFeeCounter === 2 || mscFeeCounter === 3
            ? values?.miscellaneous_fees_two
            : "0",
        miscellaneous_fees_two_note:
          mscFeeCounter === 2 || mscFeeCounter === 3
            ? values?.miscellaneous_fees_two_note
            : "",
        miscellaneous_fees_three:
          mscFeeCounter === 3 ? values?.miscellaneous_fees_three : "0",
        miscellaneous_fees_three_note:
          mscFeeCounter === 3 ? values?.miscellaneous_fees_three_note : "",
      }).unwrap();
      // console.log({ response })
      if (response.success) {
        closeDialog();
        resetActions();
        actions.resetForm();
        navigate(`/dashboard/work-orders/view/${id}`, { state: response });
      }
    } catch (error) {
      // console.log({ error })
      toast.error(
        error?.data?.message ? error?.data?.message : "No server response",
        {
          containerId: "parentToast",
          closeOnClick: true,
          toastId: 12,
          autoClose: 1000,
        }
      );
    }
  };
  const onEditFormSubmit = async (values, actions) => {
    let packageData = { ...values, cost: values?.cost.toString() };
    if (values.type === "other") {
      packageData = { ...packageData, width: "", height: "", length: "" };
    }
    if (values.wantToUseCalculator) {
      packageData = {
        ...packageData,
        customCostPerPound: "",
        costPerPound:
          workorder?.type === "consolidation"
            ? costsettingQureyResult.currentData?.value
            : "",
      };
    }

    const imagesPath = values?.images.filter(
      (item) => typeof item === "string"
    );
    const oldImagesPath = currentItem?.pics_url?.filter(
      (item) => !imagesPath?.includes(item)
    );
    try {
      const response = await updateRequest({
        id: id,
        pId: currentItem._id,
        ...packageData,
        oldImagesPath,
      }).unwrap();
      if (response.success) {
        closeDialog();
        resetActions();
        actions.resetForm();
        navigate(`/dashboard/work-orders/view/${id}`, { state: response });
      }
    } catch (error) {
      toast.error(
        error?.data?.message ? error?.data?.message : "No server response",
        {
          containerId: "parentToast",
          closeOnClick: true,
          toastId: 12,
          autoClose: 1000,
        }
      );
    }
  };

  const onSort = (columnName) => {
    setSortQuery((prev) => ({
      field: columnName,
      dir:
        prev.field === columnName
          ? prev.dir === "default"
            ? "asc"
            : prev.dir === "asc"
            ? "dsc"
            : prev.dir === "dsc" && "asc"
          : "asc",
    }));
  };
  const handleRowSelect = (rowId) => {
    // setSelectedRow(() => {
    //   const updateSelectedRows = selectedRow === rowId ? "" : rowId;
    //   return updateSelectedRows;
    // });
    setSelectedRow((ids) => {
      const updateSelectedRows = selectedRow.includes(rowId)
        ? selectedRow.filter((id) => id !== rowId)
        : [...ids, rowId];
      return updateSelectedRows;
    });
  };

  const onUpdatePackageClick = () => {
    // console.log("Editing");
    startEditingBulk();
    setIsOpenDrawer(true);

    // setDialogType("edit");
    // openDialog();
  };

  const onSubmitBulkUpdate = async (values, actions) => {
    // console.log({ values, actions });
    // console.log({ selectedRow });
    const ids = [...selectedRow];
    const data = {
      favourite:
        values.favourite === "addToFavorite"
          ? true
          : values.favourite === "removeFromFavorite"
          ? false
          : values.favourite,
      package_check_off: values?.package_check_off,
    };

    try {
      const response = await updateBulkPackagesRequest({
        id,
        ids,
        ...data,
      }).unwrap();
      toast.success(response.message, {
        containerId: "successToastpkg",
        closeOnClick: true,
        toastId: 122,
        autoClose: 1000,
      });
      setIsOpenDrawer(false);
      if (response?.success) {
        navigate(`/dashboard/work-orders/view/${id}`, { state: response });
        // toast.success(response?.message);
        refetch();
      }
    } catch (error) {
      toast.error(
        error?.data?.message ? error?.data?.message : "No server response",
        {
          containerId: "parentToast",
          closeOnClick: true,
          toastId: 12,
          autoClose: 1000,
        }
      );
    }
  };
  // console.log({ isEditingBulk });

  // const onToogleFav = async () => {
  //   try {
  //     const response = await toogleFavRequest({ id: selectedRow }).unwrap();
  //     // console.log({ response });
  //     if (response?.success) {
  //       navigate(`/dashboard/work-orders/view/${id}`, { state: response });
  //       // toast.success(response?.message);
  //       refetch();
  //     }
  //   } catch (error) {
  //     toast.error(
  //       error?.data?.message ? error?.data?.message : "No server response",
  //       {
  //         containerId: "parentToast",
  //         closeOnClick: true,
  //         toastId: 12,
  //         autoClose: 1000,
  //       }
  //     );
  //   }
  // };

  // const onCheckOffClick = async (status, pkgid) => {
  //   // console.log({ status, item });
  //   try {
  //     const response = await toogleCheckRequest({
  //       id: pkgid,
  //       checkoff: status,
  //     }).unwrap();
  //     // console.log({ response });
  //     if (response?.success) {
  //       navigate(`/dashboard/work-orders/view/${id}`, { state: response });
  //       // toast.success(response?.message);
  //       refetch();
  //     }
  //   } catch (error) {
  //     toast.error(
  //       error?.data?.message ? error?.data?.message : "No server response",
  //       {
  //         containerId: "parentToast",
  //         closeOnClick: true,
  //         toastId: 12,
  //         autoClose: 1000,
  //       }
  //     );
  //   }
  // };

  useEffect(() => {
    setSortQuery({
      field: "",
      dir: "asc",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ToastContainer containerId={"parentToast"} />
      <ToastContainer containerId={"successToastpkg"} />
      <div className="font-semibold bg-blue-200 rounded-md inline-block px-2 py-1 ml-4">
        {Number(workorder?.total_weight).toFixed(2) ===
        Number(calculateTotalWeight(data)).toFixed(2)
          ? "Workorder fee Calculation Up-to-Date"
          : "New package added. Update the fee to reflect the changes"}
      </div>
      {/* <h1>{}</h1>
      <h1>{}</h1> */}
      <div className=" flex flex-col gap-1 bg-white shadow-lg">
        {isAdding && (
          <Dialog isOpen={open} handleClose={closeDialog}>
            <AddPackageDialog
              isLoading={isLoadingAddMt}
              handleClose={closeDialog}
              onFormSubmit={onAddFormSubmit}
              type={type}
            />
          </Dialog>
        )}
        {isEditing && (
          <Dialog isOpen={open} handleClose={closeDialog}>
            <EditWorkOrderPackage
              currentItem={currentItem}
              isLoading={isLoadingAddMt}
              handleClose={closeDialog}
              onFormSubmit={onEditFormSubmit}
              type={type}
            />
          </Dialog>
        )}
        {isDeleting && (
          <DeleteDialog
            isLoading={isLoadingDeleteMt}
            onSubmitForm={onDeleteDialogClick}
            handleClose={closeDialog}
            open={open}
          />
        )}
        {isAddingFee && (
          <Dialog isOpen={open} handleClose={closeDialog}>
            <FeeDialog
              setTotalWeight={setTotalWeight}
              totalWeight={totalWeight}
              mscFeeCounter={mscFeeCounter}
              setMscFeeCounter={setMscFeeCounter}
              data={data}
              workorder={workorder}
              isLoading={addFeeRequestQueryResult.isLoading}
              handleClose={closeDialog}
              onFormSubmit={onAddFeeFormSubmit}
              type={type}
            />
          </Dialog>
        )}
        <Drawer isOpen={isOpenDrawer} setIsOpen={setIsOpenDrawer}>
          <UpdateContent
            isLoading={false}
            onSubmitBulkUpdate={onSubmitBulkUpdate}
            setIsOpen={setIsOpenDrawer}
          />
        </Drawer>
        <TableTopHeader
          getSortedData={getSortedData}
          selectedRow={selectedRow}
          // onRefreshClick={onRefreshClick}
          searchTitle={"Title"}
          addBtn={true}
          addBtnFee={true}
          addBtnFav={true}
          addBtnCheck={true}
          updateBtn={true}
          removeBtnCheck={true}
          removeBtnFav={true}
          addBtnTitle={"Add Package"}
          // addBtnFavTitle={"Add To Favourite"}
          updateBtnTitle={"Update Packages"}
          addBtnFeeTitle={"Update fee"}
          // removeBtnFavTitle={"Remove From Favourtie"}
          // refreshBtn={true}
          onAddClick={onAddClick}
          // onToggleFav={onToogleFav}
          onAddFeeClick={onAddFeeClick}
          setQuery={setSearchQuery}
          onUpdateBtnClick={onUpdatePackageClick}
          // addBtnChecktitle={"Mark as Checked Off"}
          // removeBtnChecktitle={"Revert Check Off"}
          // onCheckOffClick={onCheckOffClick}
        />
        {/* table wrapper */}
        <div className="flex flex-col min-h-[25em] justify-between px-2">
          {/* table head and body wrapper */}
          <table className="table-fixed border-collapse w-[100%] max-w-[100%] whitespace-nowrap">
            <TableHeader
              highlight={true}
              columns={packagecolumns}
              onSort={onSort}
              sortQuery={sortQuery}
            />
            <TableBody
              highlight={true}
              // hover={false}
              showEditButton={true}
              showDeleteButton={true}
              // showViewButton={true}
              // onViewItem={onViewItemClick}
              onEditItem={onEditItemClick}
              onDeleteItem={onDeleteItemClick}
              columns={packagecolumns}
              currentTableData={getSortedData}
              // isLoading={favRequestQueryResult.isLoading}
              // isLoading={true}
              // selectedRows={null} // Pass selected rows state
              // handleRowSelect={() => {}}
              selectedRows={selectedRow} // Pass selected rows state
              handleRowSelect={handleRowSelect}
            />
          </table>
          <TableFooter
            isLoading={false}
            currentPage={currentPage}
            pageSize={pageSize}
            setCurrentPage={goToPage}
            setPageSize={setItemsPerPage}
            currentTableData={currentTableData}
            totalCount={data?.length}
          />
        </div>
      </div>
    </>
  );
};

export default WOPackageTable;
