import React from "react";
import { useUpdateBulkWorkOrderMutation } from "../../../../../app/services/workOrderSlice";
import {
  Model,
  initialValues,
  validationSchema,
} from "../../../../../formModel/update/package";
import { BackDropLoader } from "../../../../common/loader";
import { Formik } from "formik";
import { ToastContainer } from "react-toastify";
import { Datepicker, Select } from "../../../../common/formElements";
import UpdateArrivalDate from "../../../../common/Dialog/UpdateArrivalDate";

const UpdateContent = ({ setIsOpen, onSubmitBulkUpdate, isLoading }) => {
  const {
    formField: { check_off, favorite },
  } = Model;

  const onFormSubmit = async (values, actions) => {
    onSubmitBulkUpdate(values, actions);
  };

  return (
    <div className="flex flex-col gap-8 justify-between  h-full py-3">
      {isLoading && <BackDropLoader />}

      <Formik
        // enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => onFormSubmit(values, actions)}
      >
        {(formik) => {
          const handleClearAll = (e) => {
            e.preventDefault();
            formik.resetForm(); // Reset form values to their initial state
          };

          return (
            <form className="h-full flex flex-col">
              <ToastContainer containerId={"error"} />

              <div className="flex flex-col gap-6 w-[70%] mx-auto">
                <div className="flex gap-3 flex-col w-full ">
                  <Select
                    fieldlabel={favorite.label}
                    placeholder={favorite.placeholder}
                    name={favorite.name}
                    options={[
                      {
                        value: "addToFavorite",
                        label: "Add To Favorite",
                      },
                      {
                        value: "removeFromFavorite",
                        label: "Remove From Favorite",
                      },
                    ]}
                  />
                  <Select
                    fieldlabel={check_off.label}
                    placeholder={check_off.placeholder}
                    name={check_off.name}
                    options={[
                      {
                        value: "checked-off",
                        label: "Mark Check Off",
                      },
                      {
                        value: "not-checked-off",
                        label: "Remove Check Off",
                      },
                    ]}
                  />
                </div>

                <button
                  type="submit"
                  onClick={formik.handleSubmit}
                  className="self-end bg-blue-500 text-white px-3 py-[0.3em] rounded-md  hover:bg-blue-700 font-semibold"
                >
                  Update
                </button>
              </div>
              <div className="w-[90%] mx-auto flex flex-col gap-2 mt-auto">
                <button
                  onClick={handleClearAll}
                  className="bg-red-400 w-full py-[0.6em] font-bold rounded-lg hover:bg-red-500 text-white"
                >
                  Clear All
                </button>
                <button
                  onClick={() => setIsOpen(false)}
                  className="bg-gray-400 w-full py-[0.6em] font-bold rounded-lg hover:bg-gray-500 text-white"
                >
                  Close
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default UpdateContent;
