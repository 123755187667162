import { apiSlice } from "./apiSlice";

const basePath = "/cost-settings";

const slice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCostSetting: builder.query({
      // Use destructuring directly in the query argument to clean up the code
      query: ({ name }) => {
        // Check if 'name' is provided and not just an empty string
        const queryParam = name ? `?name=${encodeURIComponent(name)}` : "";
        return `${basePath}${queryParam}`;
      },
      // Transform the response to handle possible undefined or null results safely
      transformResponse: (response) => response?.result ?? null,
    }),
  }),
});

export const { useGetCostSettingQuery } = slice;
