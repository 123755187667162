import * as Yup from "yup";

export const Model = {
  formField: {
    favorite: {
      name: "favourite",
      label: "Add or Remove Favorite",
      placeholder: "Set Favorite",
    },
    check_off: {
      name: "package_check_off",
      label: "Add or Remove Check Off",
      placeholder: "Set Check Off",
    },
  },
};

const {
  formField: { favorite, check_off },
} = Model;

export const initialValues = {
  [favorite.name]: "",
  [check_off.name]: "",
};

export const validationSchema = Yup.object({
  [favorite.name]: Yup.string(),
  [check_off.name]: Yup.string(),
});
