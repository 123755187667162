import * as Yup from "yup";

export const Model = {
  formField: {
    title: {
      name: "title",
      label: "Title",
    },
    length: {
      name: "length",
      label: "Length (inches)",
      placeholder: "Enter length",
    },
    width: {
      name: "width",
      label: "Width (inches)",
      placeholder: "Enter width",
    },
    height: {
      name: "height",
      label: "Height (inches)",
      placeholder: "Enter height",
    },
    cost: {
      name: "cost",
      label: "Cost",
    },
    type: {
      name: "type",
      label: "Package Type",
      placeholder: "Select Package Type",
    },
    weight: {
      name: "weight",
      label: "Package Weight (lbs)",
      placeholder: "Enter Weight of Package (lbs)",
    },
    wantToUseCalculator: {
      name: "wantToUseCalculator",
      label: "Would you like to use the cost calculator",
      desc: "Check this to enable the cost calculator",
    },
    customCostPerPound: {
      name: "customCostPerPound",
      label: "Custom cost per Pound",
      desc: "Enter the cost per pound for manual shipping cost calculation.",
    },
    packageContent: {
      name: "package_content",
      label: "Package Content",
    },
    location: {
      name: "location",
      label: "Add Location",
    },

    images: {
      name: "images",
      label: "Add Pictures",
    },
    packageCheckOff: {
      name: "package_check_off",
      label: "packageCheckOff",
    },
  },
};

const {
  formField: {
    cost,
    height,
    length,
    title,
    width,
    images,
    wantToUseCalculator,
    packageContent,
    type,
    weight,
    packageCheckOff,
    customCostPerPound,
    location,
    // lastFreeDay,
  },
} = Model;

export const initialValues = {
  [title.name]: "",
  [length.name]: "",
  [width.name]: "",
  [height.name]: "",
  [cost.name]: "",
  [packageContent.name]: "",
  [images.name]: [],
  [wantToUseCalculator.name]: true,
  [type.name]: "other",
  [weight.name]: "",
  [packageCheckOff.name]: "",
  [customCostPerPound.name]: "",
  [location.name]: "",
  // [lastFreeDay.name]: new Date(),
};

export const validationSchema = Yup.object({
  [title.name]: Yup.string().required("Title is required"),
  [location.name]: Yup.string(),
  // [lastFreeDay.name]: Yup.date(),
  [length.name]: Yup.string().when(type.name, {
    is: (type) => type === "box",
    then: () => Yup.string().required("Length is required"),
  }),
  [width.name]: Yup.string().when(type.name, {
    is: (type) => type === "box",
    then: () => Yup.string().required("Width is required"),
  }),
  [height.name]: Yup.string().when(type.name, {
    is: (type) => type === "box",
    then: () => Yup.string().required("Height is required"),
  }),
  [cost.name]: Yup.string(),
  [packageContent.name]: Yup.string(),
  [images.name]: Yup.array(),
  [wantToUseCalculator.name]: Yup.boolean(),
  [type.name]: Yup.string().required("Package type is required"),
  [weight.name]: Yup.string().when(type.name, {
    is: (type) => type === "other",
    then: () => Yup.string().required("Weight is required"),
  }),
  [customCostPerPound.name]: Yup.string().when(wantToUseCalculator.name, {
    is: (type) => type === false,
    then: () => Yup.string().required("Custom cost per pound is required"),
  }),
  [packageCheckOff.name]: Yup.string(),
});
