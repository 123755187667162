import React from "react";
import SideMenuContext from "../../context/SideMenuContext";
import DashboardLayout from "./DashboardLayout";

const OecDashboard = () => {
  return (
    <SideMenuContext>
      <DashboardLayout />
    </SideMenuContext>
  );
};

export default OecDashboard;
