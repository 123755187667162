import { useCallback, useState } from "react";

const useButtonActions = () => {
  const [actionState, setActionState] = useState({
    currentItem: null,
    isEditing: false,
    isDeleting: false,
    isDeletingByDate: false,
    isViewing: false,
    isAdding: false,
    isAddingFee: false,
    isEditingBulk: false,
  });

  const setAction = useCallback((action, item = null) => {
    setActionState({
      currentItem: item,
      isEditing: action === "editing",
      isDeleting: action === "deleting",
      isDeletingByDate: action === "deletingByDate",
      isViewing: action === "viewing",
      isAdding: action === "adding",
      isAddingFee: action === "addingFee",
      isEditingBulk: action === "editingBulk",
    });
  }, []);

  const resetActions = useCallback(() => {
    setActionState({
      currentItem: null,
      isEditing: false,
      isDeleting: false,
      isDeletingByDate: false,
      isViewing: false,
      isAdding: false,
      isAddingFee: false,
      isEditingBulk: false,
    });
  }, []);
  return {
    ...actionState,
    startEditing: (item) => setAction("editing", item),
    startDeleting: (item) => setAction("deleting", item),
    startDeletingByDate: () => setAction("deletingByDate"),
    startViewing: (item) => setAction("viewing", item),
    startAdding: () => setAction("adding"),
    startAddingFee: () => setAction("addingFee"),
    startEditingBulk: () => setAction("editingBulk"),
    resetActions,
  };
};

export default useButtonActions;
